import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import TwoColumns from "../components/Studies/TwoColumns"

// Functional component
const CasosDeExito = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Casos de Éxito"
        homePageText="Inicio"
        homePageUrl="/"
        activePageText="Casos de Éxito"
      />
      <TwoColumns />
      <Footer />
    </Layout>
  )
}

export default CasosDeExito
